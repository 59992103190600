<template>
  <div>
    <header id="nav" class="flex px-5 justify-between">
        <div class="flex lg:hidden items-center space-x-1 h-24 ">
          <img class="object-scale-down h-32 mt-6" src="@/assets/logo.png">
        </div>
        <div class="hidden lg:flex items-center space-x-1 h-24">
          <img class="object-scale-down h-32 mt-6" src="@/assets/logo_no_name.png">
        </div>
        <div class="hidden lg:flex items-center space-x-1 crazy">
          Crazy Sign Ladies
        </div>
        <div class="hidden md:flex flex-col justify-center space-y-3">
          <div class='items-center flex space-x-3'>
            <div class='linkholder'><router-link to="/">Home</router-link></div><div>|</div>
            <div class='linkholder'><router-link to="/products">Products</router-link></div><div>|</div>
            <div class='linkholder'><router-link to="/contact">Contact</router-link></div><div>|</div>
            <div class='linkholder'><router-link to="/policies">Policies</router-link></div><div>|</div>
            <div class='linkholder'><router-link to="/about">About</router-link></div>          
          </div>
          <div class='justify-end flex space-x-3'>
            <a target="_blank" href="https://www.facebook.com/CrazySignLadiesKY"><font-awesome-icon size='2x' :icon="['fab', 'facebook']" /></a>
            <a target="_blank" href="https://www.instagram.com/crazysignladiesky"><font-awesome-icon size='2x' :icon="['fab', 'instagram']" /></a>
          </div>
        </div>
        <div class = "flex items-end md:hidden">
          <div class='justify-end flex space-x-3'>
            <a target="_blank" href="https://www.facebook.com/CrazySignLadiesKY"><font-awesome-icon size='2x' :icon="['fab', 'facebook']" /></a>
            <a target="_blank" href="https://www.instagram.com/crazysignladiesky"><font-awesome-icon size='2x' :icon="['fab', 'instagram']" /></a>
          </div>  
        </div>
        <div class="items-center flex md:hidden  flex-col space-x-3">
          <div class='linkholder'><router-link to="/">Home</router-link></div>
          <div class='linkholder'><router-link to="/products">Products</router-link></div>
          <div class='linkholder'><router-link to="/contact">Contact</router-link></div>
          <div class='linkholder'><router-link to="/policies">Policies</router-link></div>
          <div class='linkholder'><router-link to="/about">About</router-link>
          </div>                  
        </div>
    </header>
    <hr class='darkhr'/>
    <div class="content_view">
      <router-view/>
    </div>
  </div>
</template>
<!-- #93eb7d ,#ecfd26,#ef782c, #ff4179, #b768f6, #26F0fd -->
<style>
  @import url('https://fonts.googleapis.com/css2?family=Atma:wght@700&display=swap');

  @font-face {
      font-family: 'Wild And Crazy Medium';
      font-style: normal;
      font-weight: normal;
      src: local('/fonts/crazy/Wild And Crazy Medium'), url('/fonts/crazy/Wild And Crazy Medium.woff') format('woff');
      }

  #app {
    font-family: 'Avenir, Helvetica, Arial, sans-serif';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;    
    color: #ffffff;   
    height: 100vh;
    background: linear-gradient(#2c3e50,  #000);           
  }
  body {
        
  }
  .linkholder {
    color: #b768f6;
  }
  a {
    font-weight: bold;
    font-family:'Atma', cursive;    
  }
  #nav a {
    text-decoration: none;
  }
  .crazy {    
    margin:auto;
    font-family: 'Atma', cursive;
    font-size: 36px;
    background: -webkit-linear-gradient(left,#93eb7d ,#ecfd26,#ef782c, #ff4179, #b768f6, #26F0fd);
    background: -o-linear-gradient(right,#93eb7d,  #ecfd26,#ef782c, #ff4179, #b768f6, #26F0fd);
    background: -moz-linear-gradient(right,#93eb7d,  #ecfd26,#ef782c, #ff4179, #b768f6, #26F0fd);
    background: linear-gradient(to right,#93eb7d,  #ecfd26,#ef782c, #ff4179, #b768f6, #26F0fd); 
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  #nav a.router-link-exact-active {
    color: #ef782c;
  }
  .content_view {
    height: calc(100vh - 200px);
    overflow:auto;
  }
  hr.darkhr {
    display: block; height: 1px;
    border: 0; border-top: 1px solid #aaa;
    margin: 1em 0; padding: 0;    

}
.nav {
  
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}
.logo {
  width:300px;  
  min-width:150px;
}

  
</style>
  