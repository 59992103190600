<template>
  <div>
      <transition name="slide-fade">
<!-- 			SRC comes from the array of images the :key is important for vue to believe its a 'new' DOM element and do the transition -->
			<img class='theImage object-scale-down m-auto' :src="images[currentImageIndex]" :key="currentImageIndex"/>
    </transition>
	

  </div>
</template>

<script>
export default {
  name: 'PhotoScroller',
  computed: {
  
  },
  created () {        
        this.timer = setInterval(this.nextImage, 4000);
  },  
  data() {
    return {      
      currentImageIndex: 0,
      images: ['/images/sample1.jpg', '/images/sample2.jpg', '/images/sample3.jpg', '/images/sample4.jpg', 'images/sample5.jpg'],
      timer: ''
    }
  },
  methods: {
    nextImage(){
      this.currentImageIndex = (this.currentImageIndex + 1) % (this.images.length); 
    }
  }  
}
</script>

<style scoped>
  .theImage {
  position:absolute;
  width: 100vw;
  height: calc(100vh - 450px);
}

button {
  z-index: 100;
  position: relative;
  width: 200px;
  height: 25px;
  margin: 20px;
}

/* prefix with transition name */



.slide-fade-enter-active {
  opacity: 1;
  z-index: 4;
  -webkit-transition: all 6s ease;
  transition: all 6s ease;

}

.slide-fade-leave-active {
  opacity: 1;
  z-index:5;
  -webkit-transition: all 3s ease;
  transition: all 3s ease;

}

.slide-fade-enter{
  opacity: 0;
}
.slide-fade-leave-to {
  opacity: 0;
}
</style>