import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'; // <-- TS2307 squiggles
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
//import { faRedo } from '@fortawesome/free-solid-svg-icons';

import "tailwindcss/tailwind.css";
import './assets/styles.css'; 

library.add(faFacebook, faInstagram);

createApp(App).use(router).component("font-awesome-icon", FontAwesomeIcon).mount('#app')
