<template>
  <div class="home">
    <PhotoScroller/>
  </div>
</template>

<script>
// @ is an alias to /src
import PhotoScroller from '@/components/PhotoScroller.vue'

export default {
  name: 'Home',
  components: {
    PhotoScroller
  }
}
</script>
